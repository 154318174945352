<template>
  <div>
    <div class="upload">
      <p class="theme-color">{{idType}}</p>
      <div class="image-id">
        <input
          type="file"
          accept="image/*"
          class="image"
          id="uploadImage1"
          style="visibility: hidden"
          @change="selectFileImage1($event)"
        />
        <label for="uploadImage1">
          <van-image class="showimage-id" :src="pic1 || tipPic[0]"></van-image>
        </label>
      </div>
    </div>
    <div class="upload" v-if="idType !== '护照'">
      <div class="image-id">
        <input
          type="file"
          accept="image/*"
          class="image"
          id="uploadImage2"
          style="visibility: hidden"
          @change="selectFileImage2($event)"
        />
        <label for="uploadImage2">
          <van-image class="showimage-id" :src="pic2 || tipPic[1]"></van-image>
        </label>
      </div>
    </div>
    <div class="upload-tip theme-color">点击图片上传或更新</div>
    <div class="common-id-tip theme-color">
      <span >提示：<br /></span>
      <span >1.拍摄时请确保证件平放，光线充足。<br /></span>
      <span >2.确保文字清晰、边框完整。<br /></span>
      <span  v-if="idType === '其它'">3.至少需要上传一张有效照片。<br /></span>
      <span
        v-if="idType === '身份证'">3.示例（点击可查看）：<span class="link" @click="showPreview('https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/temp_2ID_01.jpg')">
          身份证人像面
      </span>、<span class="link" @click="showPreview('https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/temp_2ID_02.jpg')">
        身份证非人像面
    </span>。<br /></span>
      <span
        v-if="idType === '护照'">3.示例（点击可查看）：<span class="link" @click="showPreview('https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/temp_2passport.jpg')">
          护照
      </span>。<br /></span>
      <span
        v-if="idType === '其它'">4.示例（点击可查看）：<span class="link" @click="showPreview('https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/temp_3other_01.jpg')">
          其它证件人像面
      </span>、<span class="link" @click="showPreview('https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/temp_3other_02.jpg')">
        其它证件非人像面
      </span>。<br /></span>
    </div>

  </div>
</template>

<script>
import {
  Uploader, Image, ImagePreview,
} from 'vant';
import { useStore } from 'vuex';
import { onMounted, ref } from 'vue';
import httpApi from '../../utils/httpApi';
import uploadImage from './uploadImage';

const id1 = 'https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/2-1_ID1.png';
const id2 = 'https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/2-2_ID2.png';
const pa1 = 'https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/2-3_passport.png';
const ot1 = 'https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/2-4_other1.png';
const ot2 = 'https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/2-4_other2.png';

export default {
  name: 'Home',
  components: {
    [Uploader.name]: Uploader,
    [Image.name]: Image,
  },
  data() {
    return {
      type: '',
      fileList: [],
    };
  },
  methods: {
    async selectFileImage1(event) {
      const file = event.target.files[0];
      uploadImage(file, 'id_photo_0', (photo) => {
        this.pic1 = photo;
      });
    },
    async selectFileImage2(event) {
      const file = event.target.files[0];
      uploadImage(file, 'id_photo_1', (photo) => {
        this.pic2 = photo;
      });
    },
    showPreview(key) {
      ImagePreview([key]);
    },
    link(name) {
      this.$router.push({ name });
    },
    next() {
      this.$router.push({ path: '/info2' });
    },
    before() {
      this.$router.go(-1);
    },
  },
  setup() {
    const idType = ref('');
    const pic1 = ref('');
    const pic2 = ref('');
    const tipPic = ref([]);
    const fileList1 = ref([]);
    const fileList2 = ref([]);
    onMounted(async () => {
      const store = useStore();
      store.commit('setTitleValue', '上传资料');
      store.commit('setStepsShow', false);
      const result = await httpApi.getUploadImgesStatus();
      if (!result) return;
      idType.value = result.data.type;
      const res1 = await httpApi.getUploadImgesByType({ type: 'id_photo_0' });
      if (!res1) return;
      pic1.value = res1.data.value;
      if (idType.value !== '护照') {
        const res2 = await httpApi.getUploadImgesByType({ type: 'id_photo_1' });
        if (res2) pic2.value = res2.data.value;
      }
      if (idType.value.endsWith('身份证')) {
        tipPic.value[0] = id1;
        tipPic.value[1] = id2;
      } else if (idType.value === '护照') {
        tipPic.value[0] = pa1;
      } else if (idType.value === '其它') {
        tipPic.value[0] = ot1;
        tipPic.value[1] = ot2;
      }
    });
    return {
      idType,
      tipPic,
      pic1,
      pic2,
      fileList1,
      fileList2,
    };
  },
};
</script>

<style lang="less">
  .image-id {
    width: 300px;
    height: 200px;
    margin: auto;
    position: relative;
  }
  .showimage-id {
    position: absolute;
    width: 300px;
    height: 200px;
    top: 0;
    left: 0;
  }
  .upload-tip {
    text-align: center !important;
    color: @font-color-2;
    margin-top: @space-2;
  }
  .common-id-tip {
    margin-top: 10px;
    color: @font-color-1;
    text-align: left;
    padding-left: @space-0;
    padding-right: @space-0;
  }
</style>
